import React from 'react';

function App() {
  return (
    <div className="flex flex-col items-center justify-center w-full h-[100vh]">

        <img src="/logo.svg" alt="Logo Stroke" className="w-[110px] h-auto"/>

        <h1 className="text-3xl font-recoleta mt-8">
            Strona w przebudowie
        </h1>

        <p className="font-antario mx-12 text-center mt-4">
            W między czasie zapraszamy do
            umawiania wizyt na portalu Booksy
        </p>

        <a href="https://booksy.com/pl-pl/101057_hand-beauty-spa_salon-kosmetyczny_12614_tychy">

            <img src="/booksy-inc-logo-vector.svg" alt="Logo Stroke" className="w-auto h-[180px] rise-shake"/>

        </a>

    </div>
  );
}

export default App;
